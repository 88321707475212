<template>
  <div class="navigation">
    <div class="title">
      <span>数据字典 / </span> <span class="black">心理机构导航地图</span>
    </div>
    <el-card class="condition_style2">
      <div class="condition_div">
        <el-form :inline="true" class="flex">
          <el-form-item>
            <el-button type="primary" size="small" @click="add('')">新增机构</el-button>
          </el-form-item>
          <div>
            <el-form-item>
              <el-input v-model="searchForm.institutionName" placeholder="请输入机构名称" suffix-icon="el-icon-search"
                clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-select placeholder="请选择分类" filterable v-model="searchForm.institutionType" clearable>
                <el-option v-for="(item, index) in typeList" :key="index" :label="item.typeName" :value="item.typeId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="getList(true)">查询</el-button>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <el-table :data="tableData" style="width: 100%" class="table" :height="tableHeight">
        <el-table-column type="index" label="序号" width="60" align="center" fixed>
          <template slot-scope="scope">{{scope.$index+(current-1) * size + 1}}</template>
        </el-table-column>
        <el-table-column prop="institutionName" label="机构名称" min-width="120" align="center"></el-table-column>
        <el-table-column prop="institutionTypeName" label="所属分类" min-width="100" align="center"></el-table-column>
        <el-table-column prop="address" label="详细地址" min-width="250" align="center">
          <template slot-scope="scope">
            {{scope.row.provinceName}}{{scope.row.cityName}}{{scope.row.districtName}}{{scope.row.address}}
          </template>
        </el-table-column>
        <el-table-column prop="workTime" label="工作时间" min-width="180" align="center"></el-table-column>
        <el-table-column prop="longitude" label="经纬度" min-width="320" align="center">
          <template slot-scope="scope"> {{scope.row.lat }} , {{scope.row.lng}}</template>
        </el-table-column>
        <el-table-column prop="status" label="当前状态" min-width="80" align="center">
          <template slot-scope="scope"> {{scope.row.status == 1?"禁用":"启用" }}</template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="150" align="center">
          <template slot-scope="scope">
            <el-button class="ge" type="text" size="small" @click="changeState(scope.row,1)">
              {{scope.row.status==1?"启用":"禁用"}}</el-button><span class="ge">|</span>
            <el-button type="text" size="small" @click="add(scope.row)"> 编辑</el-button><span class="ge"> |</span>
            <el-button type="text" size="small" @click="changeState(scope.row,false)">删除 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination :page="current" :size="size" :total="total" @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"></pagination>
    </el-card>
    <!-- 新增\编辑 -->
    <modal :obj="{
        title: edit ? '编辑机构' : '新增机构',
        dialog: addDialog,
        dialogText: 'addDialog',
        form: addForm,
        ref: 'addForm',
      }" @submit="addSubmit">
      <el-form :model="addForm" label-width="130px" :rules="rules" ref="addForm">
        <el-form-item label="机构名称：" prop="institutionName">
          <el-input v-model="addForm.institutionName" placeholder="请输入机构名称"></el-input>
        </el-form-item>
        <el-form-item label="所属分类：" prop="institutionType">
          <el-select placeholder="请选择所属分类" class="w_100" v-model="addForm.institutionType">
            <el-option v-for="(item, index) in  addTypeList" :key="index" :label="item.typeName" :value="item.typeId"
              v-show="item.typeId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属区域：" prop="area">
          <el-cascader class="w_100" v-model="addForm.area" :options="areaLevelList"
            :props="{ value: 'id', label: 'name'  }" ref="clearCheckedNodes"></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址：" prop="address">
          <el-input v-model="addForm.address" placeholder="请输入详细地址"></el-input>
        </el-form-item>
        <el-form-item label="热线电话：" prop="hotLine">
          <el-input v-model="addForm.hotLine" placeholder="请输入热线电话"></el-input>
        </el-form-item>
        <el-form-item label="工作时间：" prop="workTime">
          <el-input v-model="addForm.workTime" placeholder="请输入工作时间"></el-input>
        </el-form-item>
        <el-form-item label="经纬度：" prop="longitudeAndLatitude">
          <el-input v-model.trim="addForm.longitudeAndLatitude" placeholder="请输入经纬度，格式：纬度,经度"></el-input>
        </el-form-item>
      </el-form>
    </modal>
  </div>
</template>

<script>
  import pagination from "@/components/pagination";
  import modal from "@/components/modal";
  import {
    areaLevel,
    topicTypes,
    psyInstSave,
    getPsyInstList,
    psyInstInfo,
    psyInstUpdate,
    psyInstStatus,
    deletePsyInst
  } from "@/api/api";
  export default {
    components: {
      pagination,
      modal,
    },
    data() {
      return {
        searchForm: {
          institutionName: "",
          institutionType: "",
        },
        size: 10, //每页条数
        current: 1, //当前条数
        total: 0,
        tableHeight: this.$util.getHeight(330),
        tableData: [],
        typeList: [],
        addTypeList: [],
        areaLevelList: [],
        addDialog: false,
        edit: "",
        addForm: {
          institutionName: "",
          institutionType: "",
          area: [],
          address: "",
          hotLine: "",
          workTime: "",
          lng: "",
          lat: "",
          content: "",
          province: "", //省id
          city: "", //市id
          district: "", //区id
          longitudeAndLatitude: "",
          psyType: 5
        },
        rules: {
          institutionName: [{
            required: true,
            message: "请输入机构名称"
          }],
          institutionType: [{
            required: true,
            message: "请选择所属分类"
          }],
          area: [{
            required: true,
            message: "请选择所属区域"
          }],
          address: [{
            required: true,
            message: "请输入详细地址"
          }],
          hotLine: [{
            required: true,
            message: "请输入热线电话"
          }],
          longitude: [{
            required: true,
            message: "请输入经度"
          }],
          latitude: [{
            required: true,
            message: "请输入纬度"
          }],
          longitudeAndLatitude: [{
            required: true,
            message: "请输入经纬度"
          }],
        },
      };
    },
    computed: {
      poewrMenuIdList() {
        return this.$store.state.poewrMenuIdList ? this.$store.state.poewrMenuIdList : []
      },
    },
    created() {
      this.getList()
    },
    mounted() {
      window.addEventListener('resize', () => {
        this.getTableHeight(330)
      })
      this.getAreaFourLevel()
      this.getThemetypeList()
    },
    methods: {
      getTableHeight(val) {
        this.tableHeight = this.$util.getHeight(val)
      },

      getList(val) {
        if (val) {
          this.current = 1;
        }
        this.$request(getPsyInstList(this.current, this.size), this.searchForm).then((res) => {
          if (res.code === 0) {
            let data = res.data;
            this.tableData = data.records;
            this.total = Number(data.total);
          }
        });
      },
      // 获取分类列表
      getThemetypeList() {
        let typeObj = {
          typeId: null,
          typeName: '全部'
        }
        this.$request(topicTypes, {
          typeTheme: 5
        }).then((res) => {
          if (res.code === 0) {
            this.typeList = this.$util.superCopy(res.data);
            this.addTypeList = this.$util.superCopy(res.data);
            this.typeList.unshift(typeObj);
          }
        });
      },
      // 获取地区
      getAreaFourLevel() {
        this.$request(areaLevel).then((res) => {
          if (res.code === 0) {
            let list = res.data
            this.areaLevelList = list
          }
        });
      },
      // 推荐、上架、删除
      changeState(val, bool) {
        let text;
        if (!bool) {
          text = "确定删除？";
        } else if (bool == 1) {
          text = val.status == 1 ? "确认启用？" : "确认禁用？";
        }
        val.bool = bool;
        this.$util.isSwal(text, this.changeStateSubmit, val);
      },
      changeStateSubmit(val) {
        let url = !val.bool ? this.$request(deletePsyInst, {
          institutionId: val.institutionId
        }) : this.$request(psyInstStatus, {
          institutionId: val.institutionId,
          status: val.status == "0" ? "1" : "0"
        });
        url.then((res) => {
          if (res.code === 0) {
            this.$util.message("suc", "操作成功");
            this.getList();
          }
        });
      },
      //   新增\编辑-弹框
      add(val) {
        this.edit = val;
        this.addDialog = true;
        this.addForm = {
          institutionName: "",
          institutionType: "",
          area: [],
          address: "",
          hotLine: "",
          workTime: "",
          lng: "",
          lat: "",
          content: "",
          province: "", //省id
          city: "", //市id
          district: "", //区id
          longitudeAndLatitude: "",
          psyType: 5
        }
        if (this.$refs.clearCheckedNodes) {
          this.$refs.clearCheckedNodes.$refs.panel.clearCheckedNodes();
          this.$refs.clearCheckedNodes.$refs.panel.activePath = [];
        }
        if (this.$refs.addForm) {
          this.$refs.addForm.resetFields();
        }
        if (!this.areaLevelList.length) {
          this.getAreaFourLevel()
        }
        if (this.edit) {
          this.getOrganizationInfo(val.institutionId);
        }
      },
      // 详情
      getOrganizationInfo(id) {
        this.$request(psyInstInfo, {
          institutionId: id
        }).then((res) => {
          if (res.code == 0) {
            this.addForm = res.data
            this.addForm.longitudeAndLatitude = this.addForm.lat + "," + this.addForm.lng
            this.addForm.area = (this.addForm.province + "," + this.addForm.city + "," + this.addForm.district)
              .split(',')
          }
        });
      },
      //   新增编辑-上传接口
      addSubmit() {
        let url = this.edit ? psyInstUpdate : psyInstSave;
        this.$refs.addForm.validate((result) => {
          if (result) {
            console.log(this.addForm, "this.addForm")
            let arr = this.addForm.longitudeAndLatitude.split(",")
            this.addForm.lng = arr[1]
            this.addForm.lat = arr[0]
            this.addForm.province = this.addForm.area[0]
            this.addForm.city = this.addForm.area[1]
            this.addForm.district = this.addForm.area[2]
            this.$request(url, this.addForm).then((res) => {
              if (res.code == 0) {
                if (res.data) {
                  this.addDialog = false;
                  this.$util.message("suc", "操作成功");
                  this.getList();
                } else {
                  this.$util.message("err", "经纬度不正确，请重新输入");
                }
              }
            });
          }
        });
      },
      handleSizeChange(val) {
        this.size = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.current = val;
        this.getList();
      },
    },
  };
</script>

<style lang="less" scoped>
  .navigation {
    .w_48_l {
      width: 60%;
      display: inline-block;
    }

    .w_48 {
      width: 40%;
      display: inline-block;

      /deep/.el-form-item__content {
        margin-left: 12px !important;
      }

    }
  }
</style>